<template>
  <editor />
</template>

<script>
import Editor from "../components/Editor";

export default {
  name: "Edit",
  components: {
    Editor
  }
};
</script>
