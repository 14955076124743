var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-tabs',{staticClass:"elevation-1",attrs:{"grow":""},on:{"change":_vm.onTabChange}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":"#tab-editor"}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencil))])],1),_c('v-tab',{attrs:{"href":"#tab-html"}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCodeTags))])],1),_c('v-tab',{attrs:{"href":"#tab-wikitext"}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCodeBraces))])],1),_c('v-tab-item',{attrs:{"value":'tab-editor'}},[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('v-toolbar',{attrs:{"dense":"","flat":"","elevation":"1"}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"text":""},on:{"click":commands.undo}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiUndo))])],1),_c('v-btn',{attrs:{"text":""},on:{"click":commands.redo}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiRedo))])],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn-toggle',{attrs:{"multiple":""}},[_c('v-btn',{class:{ 'v-btn--active': isActive.bold() },attrs:{"text":""},on:{"click":commands.bold}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatBold))])],1),_c('v-btn',{class:{ 'v-btn--active': isActive.italic() },attrs:{"text":""},on:{"click":commands.italic}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatItalic))])],1),_c('v-btn',{class:{ 'v-btn--active': isActive.underline() },attrs:{"text":""},on:{"click":commands.underline}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatUnderline))])],1),_c('v-btn',{class:{ 'v-btn--active': isActive.wikilink() },attrs:{"text":""},on:{"click":function($event){return commands.wikilink({ title: _vm.selectedText })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiLink))])],1)],1),_c('v-menu',{attrs:{"fixed":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatHeader1))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-tile',[_c('v-list-tile-content',[_c('v-btn',{class:{
                        'v-btn--active': isActive.heading({ level: 1 })
                      },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 1 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatHeader1))])],1)],1)],1),_c('v-list-tile',[_c('v-list-tile-content',[_c('v-btn',{class:{
                        'v-btn--active': isActive.heading({ level: 2 })
                      },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 2 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatHeader2))])],1)],1)],1),_c('v-list-tile',[_c('v-list-tile-content',[_c('v-btn',{class:{
                        'v-btn--active': isActive.heading({ level: 3 })
                      },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 3 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatHeader3))])],1)],1)],1),_c('v-list-tile',[_c('v-list-tile-content',[_c('v-btn',{class:{
                        'v-btn--active': isActive.heading({ level: 4 })
                      },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 4 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatHeader4))])],1)],1)],1),_c('v-list-tile',[_c('v-list-tile-content',[_c('v-btn',{class:{
                        'v-btn--active': isActive.heading({ level: 5 })
                      },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 5 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatHeader5))])],1)],1)],1)],1)],1),_c('v-btn-toggle',[_c('v-btn',{class:{ 'v-btn--active': isActive.bullet_list() },attrs:{"text":""},on:{"click":commands.bullet_list}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatListBulleted))])],1),_c('v-btn',{class:{ 'v-btn--active': isActive.ordered_list() },attrs:{"text":""},on:{"click":commands.ordered_list}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatListNumbered))])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"fixed":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-xs-only",class:{ 'v-btn--active': isActive.table() },attrs:{"text":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTable))])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(!isActive.table())?_c('span',[_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":function($event){return commands.createTable({
                          rowsCount: 3,
                          colsCount: 3,
                          withHeaderRow: false
                        })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTablePlus))])],1)],1):_vm._e(),(isActive.table())?_c('span',[_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.addRowBefore}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTableRowPlusBefore))])],1),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.addRowAfter}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTableRowPlusAfter))])],1),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.deleteRow}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTableRowRemove))])],1),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.addColumnBefore}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTableColumnPlusAfter))])],1),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.addColumnBefore}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTableColumnPlusBefore))])],1),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.deleteColumn}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTableColumnRemove))])],1),_c('v-divider'),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.toggleCellMerge}}),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"text":""},on:{"click":commands.deleteTable}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTableRemove))])],1)],1):_vm._e()])],1),_c('v-btn',{class:{ 'v-btn--active': isActive.blockquote() },attrs:{"text":""},on:{"click":commands.blockquote}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatQuoteOpen))])],1),_c('v-btn',{class:{ 'v-btn--active': isActive.code_block() },attrs:{"text":""},on:{"click":commands.code_block}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiCodeTags))])],1),_c('v-btn',{attrs:{"text":""},on:{"click":commands.horizontal_rule}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiMinus))])],1)],1)],1)]}}])}),_c('editor-menu-bubble',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var commands = ref.commands;
                        var isActive = ref.isActive;
                        var getMarkAttrs = ref.getMarkAttrs;
                        var menu = ref.menu;
return [_c('v-sheet',{staticClass:"contexttool",style:(("left: " + (menu.left) + "px; top: " + (menu.top) + "px;")),attrs:{"elevation":"1"}},[(isActive.wikilink())?_c('v-toolbar',{class:{ 'is-active': isActive.wikilink() },attrs:{"dense":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiLink))]),_c('v-text-field',{attrs:{"solo":"","flat":"","filled":"","placeholder":"Title","hide-details":"","value":getMarkAttrs('wikilink').title}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return commands.wikilink({})}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDelete))])],1),_c('v-btn',{attrs:{"icon":"","href":getMarkAttrs('wikilink').href,"target":"_blank"}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1)],1):_vm._e()],1)]}}])}),_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}})],1),_c('v-tab-item',{attrs:{"value":'tab-html'}},[_c('v-card',[_c('v-card-text',[_c('pre',[_c('code',[_vm._v(_vm._s(_vm.html))])])])],1)],1),_c('v-tab-item',{attrs:{"value":'tab-wikitext'}},[_c('v-card',[_c('v-card-text',[_c('pre',[_c('code',[_vm._v(_vm._s(_vm.wikitext))])])])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"editor px-4",attrs:{"cols":"12","sm":"12"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }